<template>
  <a-modal :title="formTitle" :visible="open" width="25%" @cancel="cancel" @ok="submitForm">
    <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 3 }" :wrapper-col="{ span: 24 }">
      <a-form-model-item label="起始里程" prop="startMileage" >
        <a-input-number v-model="form.startMileage" placeholder="字段名称" :step='1' style='width: 60%' :max="99999999"/>
      </a-form-model-item>
      <a-form-model-item   label="结束里程" prop="endMileage" >
        <a-input-number v-model="form.endMileage" placeholder="请输入配置值" :step='1' style='width: 60%' :max="99999999"/>
      </a-form-model-item>
      <a-form-model-item  label="价格" prop="price" >
        <a-input-number v-model="form.price" placeholder="请输入配置值(数字,最多8位)"   :step='0.01' style='width: 60%' :max="99999999"/>
      </a-form-model-item>

    </a-form-model>
  </a-modal>
</template>

<script>


export default {
  name: 'FieldsForm',
  data() {
    return {
      formTitle: '新增阶梯里程价',
      open: false,
      id: null,
      index: 0,
      goodsSpace:[],//配置
      goodsSpaceValue:[],//配置项
      type: null,
      form: {
        startMileage: null,
        endMileage:null,
        price: null,
      },
      rules: {
        startMileage: [
          { required: true, message: '起始里程不能为空', trigger: 'blur' }
        ],
        endMileage: [
          { required: true, message: '结束里程不能为空', trigger: 'blur' }
        ],

        price: [
          { required: true, message: '价格不能为空', trigger: 'blur' }
        ]


      },
      url: {

      },

    }
  },
  methods: {
    openModal(id, fields, index) {
      this.open = true
      this.reset()

      this.index = index

    },

    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    reset() {

      this.type = null
      this.form = {
        startMileage: null,
        endMileage:null,
        price: null,
      }
      this.index = 0
    },


    // 修改数据
    change(res) {
      this.$emit('input', '')
      this.$emit('change', res)
    },
    submitForm() {
      console.log(this.form)

      this.$emit('ok', this.form)
      this.onClose()
      return this.form
    }
  }
}
</script>

<style scoped>

</style>